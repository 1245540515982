<template>
    <div class="examsList">
        <span>token: {{userInfo.token}}</span>


    </div>
</template>

<script>
    import * as mUtils from '@/utils/mUtils'
    import {mapState, mapActions} from 'vuex'
    import XEUtils from 'xe-utils'
    import * as simpleApi from "request/simple";
    import Pagination from "@/components/pagination";

    export default {
        name: 'examsList',
        data(){
            return {
                tableData: [], tableHeight:0,
                loading:true, isShow:false,
                schoolList: [], professionList: [],
                selCource: null, selCourceDtl: null,
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null, schoolId: null},
            }
        },
        components:{
            Pagination
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
      	mounted() {
	   },
        methods: {



        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .search_container{

    }
    .btnRight{
        float: right;
        margin-right: 0px !important;
    }
    .searchArea{
        background:rgba(255,255,255,1);
        border-radius:2px;
        padding: 18px 18px 0;
    }
    .table_container{
        padding: 10px;
        background: #fff;
        border-radius: 2px;
    }
    .el-dialog--small{
       width: 600px !important;
    }
    .pagination{
        text-align: left;
        margin-top: 10px;
    }

</style>


